<template>
  <div class="container-login">
    <div class="transbox">
      <form class="content" @submit.prevent="changePassword">
        <div class="header">
          <h1 class="header-name">mobican</h1>
          <h2 class="header-comment">パスワード設定画面</h2>
        </div>

        <p class="p-0 m-0 forgot-password__text">パスワードを再発行します。</p>
        <p class="p-0 m-0 mb-4 forgot-password__text">メールアドレスを入力してください。</p>

        <!-- テナントログインID -->
        <div class="input-group">
          <input
            type="text"
            v-model="tenant_login_id"
            class="forgot-password__input form-control"
            placeholder="テナントログインID"
            @keypress="isPrevented($event)"
            @keyup.enter="changePassword"
          />
        </div>

        <!-- 社員番号 -->
        <div class="input-group">
          <input
            type="text"
            v-model="employee_number"
            class="forgot-password__input form-control"
            placeholder="社員番号"
            @keypress="isPrevented($event)"
            @keyup.enter="changePassword"
          />
        </div>

        <!-- メールアドレス -->
        <div class="input-group">
          <input
            v-model="email"
            type="text"
            class="forgot-password__input form-control"
            placeholder="メールアドレス"
            @keypress="isPrevented($event)"
            @keyup.enter="changePassword"
          />
        </div>

        <!-- submit button -->
        <button class="btn forgot-password__submit form-control mb-2">パスワード再発行</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tenant_login_id: null,
      employee_number: null,
      email: null,
      isWrongInfo: false,
      remember: false,
    };
  },
  methods: {
    changePassword() {
      axios.defaults.headers.common = {
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      let self = this;
      axios
        .post(this.$store.state.serverUrl + "reset/v1", {
          tenant_login_id: this.tenant_login_id,
          employee_number: this.employee_number,
          mail_address: this.email,
        })
        .then(function () {
          alert("パスワードが変更されました。");
          self.$router.push({ name: "Login" });
        })
        .catch((error) => {
          const errMsg = JSON.stringify(error.response.data.message);
          alert(errMsg);
        });
    },

    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
    isPrevented(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 /* allow to press enter and backspace */ &&
        (charCode < 48 || charCode > 57) /* allow to type character 0-9 */ &&
        (charCode < 64 || charCode > 90) /* allow to type capital character A-Z and @ */ &&
        (charCode < 97 || charCode > 122) /* allow to type normal character a-z */ &&
        charCode !== 46 /* allow to type '.' character */ &&
        charCode !== 45 /* allow to type '-' character */ &&
        charCode !== 95 /* allow to type '_' character */
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .transbox {
    width: 90%;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .transbox {
    width: 80%;
  }
}

@media (min-width: 993px) and (max-width: 1599px) {
  .transbox {
    width: 60%;
  }
}

@media (min-width: 1600px) {
  .transbox {
    width: 40%;
  }
}

.container-login {
  font-family: Mina;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background: #f3fbfd;
}

.transbox {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
}

.content {
  margin: 5% 15%;
}

.header-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  text-align: center;
  margin-bottom: 30px;
  color: #8bdff9;
}

.header-comment {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}

.forgot-password__input {
  height: 51.48px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 37px;
  padding-left: 22px;
  font-size: 24px;
}

.forgot-password__text {
  border: none;
  color: #000000;
  background: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  padding: 0;
}

.forgot-password__input::-webkit-input-placeholder {
  color: #c4c4c4;
}

.forgot-password__input:focus {
  border: 1px solid #3471b3;
}

.error_msg {
  font-size: 20px;
  margin-bottom: 37px;
  margin-top: -10px;
}

.forgot-password__submit {
  height: 60px;
  background: #4e92f8;
  border-radius: 10px;
  color: white;
  font-size: 24px;
}

.forgot-password__submit:hover {
  background-color: rgba(0, 45, 245, 0.829);
}

.forgot-password__submit:disabled {
  background-color: #8f8c8c;
  color: #000000;
}
</style>
